import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ClearDataService {
  private services: any[] = [];

  registerService(service: any) {
    this.services.push(service);
  }

  clearData() {
    this.services.forEach(service => {
      service.data = [];
    });
  }
}
